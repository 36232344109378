<template>
  <div id="brandCompareProfile" :ref="ref"></div>
</template>

<script>
import * as echarts from "echarts";
export default {
  data() {
    return {
      seriesData: [],
    };
  },
  mounted() {
    // this.drawBrandCompareProfile();
  },
  methods: {
    drawBrandCompareProfile() {
      // var chartDom = document.getElementById("brandCompareProfile");
      var myChart = echarts.init(this.$refs[this.ref]);
      myChart.clear();
      var option;
      option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            precision: 2,
          },
          formatter: (param) => {
            let str = param[0].name;
            param.reverse();
            for (let item of param) {
              str += `<br/>`;
              str += `${item.marker}${item.seriesName}: ${
                (item.value * 100).toFixed(2) + "%"
              }`;
            }
            return str;
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        title: {
          text: this.title,
          textStyle: {
            fontWeight: "normal",
            fontSize: 20,
            fontFamily: "Microsoft YaHei",
            color: "#4f4f4f",
          },
          left: -5,
          top: 0,
        },
        legend: {
          top: 4,
          left: 140,
          bottom: 0,
          itemGap: 18,
          itemWidth: 13,
          itemHeight: 13,
          icon: "circle",
          textStyle: {
            color: "#666",
          },
        },
        color: this.color,
        xAxis: [
          {
            type: "category",
            data: this.xAxisData,
          },
        ],
        yAxis: [
          {
            type: "value",
            show: false,
          },
        ],
        series: this.seriesData,
      };

      option && myChart.setOption(option);
    },
  },
  props: {
    xAxisData: {
      type: Array,
      default: () => {
        return ["雅诗兰黛", "兰蔻"];
      },
    },
    title: {
      type: String,
      default: "性别占比",
    },
    profileData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    color: {
      type: Array,
      default() {
        return [
          "#2c7d98",
          "#32a3be",
          "rgb(83, 186, 210)",
          "#d1eef8",
          "#f0a3b6",
          "#e37893",
          "#a02947",
        ];
      },
    },
    legend: {
      type: Array,
      default() {
        return [];
      },
    },
    ref: {
      type: String,
      default: "staggerXBar",
    },
  },
  watch: {
    xAxisData() {
      this.drawBrandCompareProfile();
    },
    profileData(val) {
      console.log(val);
      this.seriesData = [];
      val.forEach((v, i) => {
        this.seriesData.push({
          name: this.legend[i],
          type: "bar",
          stack: "age",
          barWidth: 60,
          label: {
            show: true,
            fontSize: 12,
            formatter: (param) => {
              if ((param.value * 100).toFixed(2) < 2) {
                return "";
              } else {
                return (param.value * 100).toFixed(2) + "%";
              }
            },
          },
          emphasis: {
            focus: "series",
          },
          data: v,
        });
      });
      console.log(this.seriesData);
      this.drawBrandCompareProfile();
    },
  },
};
</script>

<style lang="scss" scoped>
#brandCompareProfile {
  width: 100%;
  height: 100%;
}
</style>

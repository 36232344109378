<template>
  <div class="main">
    <div class="container">
      <div class="sovTitle">
        <div class="filter">
          <div class="left_block">
            <div class="title_block">
              <p>{{ type }}</p>
            </div>
            <div class="info_block" v-for="(item, index) in productNameList" :key="index">

              <p><img src="../logo.png" style="width:20px;"/><span class="ellipsis">{{
                  item}}</span></p>
            </div>
            <div class="date_block">
              <span>{{ cardTitle }}</span>
              <el-date-picker
                  v-model="timeRange"
                  type="monthrange"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="开始月份"
                  end-placeholder="结束月份"
                  value-format="YYYY-MM"
                  size="small"
                  :disabled-date="disabledDate"
                  :clearable="false"
              >
              </el-date-picker>
            </div>
            <div>
              <!--        <svg-icon class-name="rili" class="riliClass"></svg-icon>-->
            </div>
            <!--    <el-button size="mini" @click="forBack" class="returnBtn">返回</el-button>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getSovBrandAggMonth } from "@/api/sov/sovMain";
import storage from "@/utils/storage";
export default {
  data() {
    return {
      optionData: [
        {
          value: "1mon",
          label: "近1个月",
        },
        {
          value: "3mon",
          label: "近3个月",
        },
        {
          value: "6mon",
          label: "近6个月",
        },
        {
          value: "12mon",
          label: "近12个月",
        },
        {
          value: "thisYear",
          label: "今年至今",
        },
        {
          value: "lastYear",
          label: "去年全年",
        },
      ],
      timeRangeArr: "",
      // props: {
      //   lazy: true,
      //   lazyLoad: this.lazyLoad,
      // },
    };
  },
  props: {
    cardTitle: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "品牌",
    },
  },
  computed: {
    productNameList() {
      return JSON.stringify(this.$route.query) === "{}"
        ? []
        : JSON.parse(decodeURI(this.$route.query.productNameList)).map(
            (v) => v.label
          );
    },
    timeRange: {
      get() {
        return JSON.parse(this.$route.query.duration);
      },
      set(val) {
        console.log(val);
        const query = {
          products: this.$route.query.products,
          productNameList: this.$route.query.productNameList,
          // duration: val.length === 2 ? val[1] : val[0],
          duration: JSON.stringify(val),
        };
        this.$router.push({ name: "sovProductCompare", query });
      },
    },
  },
  mounted() {
    this.lazyLoad();
    this.timeRangeArr = storage.getItem("productTimeRange");
  },
  methods: {
    disabledDate(time) {
      if (this.timeRangeArr) {
        if (this.timeRangeArr.length > 1) {
          return (
            time.getTime() < new Date(this.timeRangeArr[0]).getTime() ||
            time.getTime() >
              new Date(
                this.timeRangeArr[this.timeRangeArr.length - 1]
              ).getTime()
          );
        } else {
          return true;
        }
      }
      // if (this.timeRangeArr.length > 1) {
      //   return (
      //     time.getTime() <
      //       new Date(
      //         this.timeRangeArr[this.timeRangeArr.length - 1]
      //       ).getTime() ||
      //     time.getTime() > new Date(this.timeRangeArr[0]).getTime()
      //   );
      // } else if (this.timeRangeArr.length < 2) {
      //   return (
      //     `${time.getFullYear()}-${time.getMonth()}` !==
      //     `${new Date(this.timeRangeArr[0]).getFullYear()}-${new Date(
      //       this.timeRangeArr[0]
      //     ).getMonth()}`
      //   );
      // } else {
      //   return true;
      // }
    },
    forBack() {
      this.$emit("forBack");
    },
    async lazyLoad() {
      console.log("hahahaha");
      let res = await getSovBrandAggMonth();
      console.log(res);
      if (res.code === 0) {
        let temp = [];
        res.data.forEach((v) => {
          temp.push({
            value: v,
            label: v,
          });
        });
        this.optionData = [
          {
            value: "zhiding",
            label: "指定月",
            children: temp,
          },
          {
            value: "1mon",
            label: "近1个月",
          },
          {
            value: "3mon",
            label: "近3个月",
          },
          {
            value: "6mon",
            label: "近6个月",
          },
          {
            value: "12mon",
            label: "近12个月",
          },
          {
            value: "thisYear",
            label: "今年至今",
          },
          {
            value: "lastYear",
            label: "去年全年",
          },
        ];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  font-size: 16px;
  position: relative;
  // top: -10px;
  background-color: #fff;
  // height: 62px;
  // line-height: 60px;
  border: 1px solid #ebebeb;
  border-radius: 20px;
  //padding: 0 20px;
  color: #11263c;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  .riliClass {
    margin-right: 5px;
    fill: #e09a29;
  }

  .sovTitle {
    display: flex;
    align-items: center;

    .filter {
      flex: 1;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .brandText {
        line-height: 59px;
        color: #11263c;

        span {
          padding: 0 10px;
          border-right: 1px solid #d4d0db;
        }

        & :first-child {
          padding-left: 0;
        }

        & :last-child {
          padding-right: 0;
          border-right: none;
        }
      }

      .iconStyle {
        font-size: 16px;
        margin-left: 10px;
        margin-right: 5px;
      }
    }

    .returnBtn {
      color: #fff;
      width: 74px;
      padding: 0;
      background: linear-gradient(180deg, #4d7ad7 0%, #5e8cec 100%);
      border-radius: 4px;
    }
  }
  .spanClass {
    // max-width: 100px;
    // height: ;
    // overflow-wrap: normal;
    // display: inline-block;
    // text-overflow: ellipsis;
    // white-space: nowrap;
    // word-break: break-all;
    // // width: 98px;
    // overflow: hidden;
  }
}
.left_block{
  background: #fff;
  border-radius: 20px;
  height:100%;
  width:100%;
  .title_block{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height:67px;
    border-bottom:1px solid #EDEDED;
    padding:0 20px;
    p{
      font-weight: 500;
      font-size:20px;
      display: flex;
      align-items: center;
    }
  }
  .info_block{
    margin:0 20px;
    border-bottom: 1px solid #ededed;
    padding: 20px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap:10px;
    .logo_block{
      width:35%;
      aspect-ratio: 1/1;
      box-shadow: 0px 0px 8.4px 0px #0000001F;
      img{
        width: 100%;
        height:100%;
      }
    }
    p{
      font-family: PingFang SC;
      font-size: 16px;
      font-weight: 500;
      line-height: 22.4px;
    }
  }
  .date_block{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding:20px;
    span{
      flex-shrink: 0;
    }
  }
}
</style>

<style lang="scss">
.filter .el-date-editor .el-range-separator {
  width: 9%;
}
.selectClass {
  .el-scrollbar__view.el-select-dropdown__list {
    margin-top: 5px;
  }

  .el-select__caret.el-input__icon.el-icon-arrow-up {
    line-height: 28px;
  }

  font-size: 14px;

  .el-input__inner {
    width: 170px !important;
    height: 28px;
  }

  .el-input--suffix {
    height: 35px;
    line-height: 35px;
  }
}
</style>

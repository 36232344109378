<template>
  <div class="publicStyle">
    <p class="publicTitleStyle">投放TA触发效率对比
      <div class="segClass">
        <span
            :style="{
          fontSize:'14px',
            marginRight: '10px',
          }"
        >目标人群</span
        >
        <el-select
            v-model="segValue"
            size="mini"
            class="m-2 autoWidth"
            @change="changeSeg"
            placeholder="请选择人群"
        >
          <template #prefix>
            {{ crowdListComputed.filter(i => i.key === segValue)[0].name }}
          </template>
          <el-option
              v-for="item in crowdListComputed"
              :key="item.key"
              :label="item.name"
              :value="item.key"
          />
        </el-select>
      </div>
    </p>
    <div class="generalViewTable">
      <el-table
        :header-cell-style="generalViewTableHeadClass"
        :row-class-name="rowStyleFunc"
        :data="tableData"
        v-loading="viewLoading"
        row-key="id"
        :tree-props="{ children: 'details' }"
      >
        <el-table-column prop="name" align="left" label="产品">
          <template #default="{ row }">
            {{ this.platformToZn(row.name) }}
          </template>
        </el-table-column>
        <el-table-column prop="taExposurePct" align="center" label="TA曝光占比">
          <template #default="{ row }">
            {{ sliceDecimal(row.taExposurePct) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="taExposure"
          align="center"
          label="触达TA总曝光量"
        >
          <template #default="{ row }">
            {{ sliceDecimal(row.taExposure) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="taEngagementQs"
          align="center"
          label="触达TA总互动量"
        >
          <template #default="{ row }">
            {{ sliceDecimal(row.taEngagementQs) }}
          </template>
        </el-table-column>
        <el-table-column prop="taCpm" align="center" label="TA CPM">
          <template #default="{ row }">
            {{ row.taCpm === "--" ? "--" : "￥" + sliceDecimal(row.taCpm) }}
          </template>
        </el-table-column>
        <el-table-column prop="taCpe" align="center" label="TA CPE">
          <template #default="{ row }">
            {{ row.taCpe === "--" ? "--" : "￥" + sliceDecimal(row.taCpe) }}
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { downloadGeneralView } from "@/api/sov/sovProduct";
import storage from "@/utils/storage";
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      generalViewTableHeadClass: {
        "background-color": "#F6F8FA",
        "font-size": "14px",
      },
      segValue: "32_1",
    };
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    tableData: {
      type: Array,
      default() {
        return [];
      },
    },
    timeRange: {
      type: String,
      default: "",
    },
    productId: {
      type: String,
      default: "",
    },
    viewLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(["productsSeg"]),
    crowdListComputed() {
      return this.formatCrowdList(storage.getItem("corwdListStorage"));
    },
  },
  watch: {
    productsSeg(val) {
      this.segValue = val;
    },
  },
  methods: {
    ...mapMutations({
      setProductsSeg: "SET_PRODUCTSSEG",
    }),
    rowStyleFunc() {
      return "otherStyle";
    },
    formatCrowdList(list = []) {
      let tempList = [];
      list.forEach((v) => {
        if (v.key !== null) {
          tempList.push(v);
        }
      });
      return tempList;
    },
    changeSeg(val) {
      console.log(111);
      this.setProductsSeg(val);
    },
    // rowStyleFunc({ rowIndex }) {
    //   console.log(rowIndex);
    //   if (rowIndex === 0) {
    //     return "firstRowStyle";
    //   } else {
    //     return "otherStyle";
    //   }
    // },
    downloadGeneralView() {
      console.log(this.timeRange);
      const params = {
        // duration: this.timeRange,
        startMonth: this.timeRange[0],
        endMonth: this.timeRange[1],
        id: this.productId,
        seg: this.productsSeg,
        // aggMonth: this.timeRange[1],
      };
      this.$message({
        type: "info",
        message: "文件正在解析，请耐心等待",
      });
      downloadGeneralView(params)
        .then(() => {
          this.$message({
            type: "success",
            message: "文件正在下载",
          });
        })
        .catch(() => {
          this.$message.error("文件下载失败,请联系管理员!");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.publicStyle {
  margin-top: 20px;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

  .publicTitleStyle {
    padding:20px 20px 0 20px;
    display:flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    color: #4f4f4f;
  }

  .downBtn {
    .segClass {
      float: right;
      margin-right: 20px;
      margin-bottom: 10px;
    }
  }

  .generalViewTable {
    margin-top: 10px;
  }
}
</style>

<style lang="scss">
.generalViewTable {
  .firstRowStyle {
    td div {
      font-size: 16px;
      color: #393a4d;
      font-weight: 500;
    }
  }

  .otherStyle {
    td div {
      font-size: 14px;
      color: #34355b;
      font-weight: 400;
    }
  }
}
.generalViewTable thead .is-left .cell {
  margin-left: 20px;
}
</style>

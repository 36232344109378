<template>
  <div class="sovProductCompareWrap">
    <div class="filterBoxFixed">
      <sovTitle cardTitle="时间范围" type="产品" @forBack="forBack"></sovTitle>
    </div>
    <div style="width:80%;">
      <sovGeneralView
          style="margin-top: 0px"
          title="产品声量概览"
          :viewLoading="viewLoading"
          :productId="products"
          :timeRange="timeRange"
          :tableData="generalViewTableData"
          :segValue="generalSegValue"
      ></sovGeneralView>
      <sovProductCompareTa
          title="投放TA触发效率对比"
          :viewLoading="taLoading"
          :productId="products"
          :timeRange="timeRange"
          :tableData="productCompareTaTableData"
          @changeSeg="changeSeg"
      >
      </sovProductCompareTa>
      <sovDistribute
          title="产品KOL投放结构对比"
          headerWordType="2"
          :isHasTotal="false"
      ></sovDistribute>
      <sovChartCart
          title="产品表现趋势"
          :isShowSelect="true"
          @timeSel="timeSel"
          @seeTotalChart="seeTotalChart"
          @seeBrandChart="seeBrandChart"
          :campaignList="productNameList"
          :echartLegend="echartLegend"
          :chartData="chartData"
      >
      </sovChartCart>

      <el-card
          :body-style="{
        height: '600px',
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 0,
      }"
          style="margin-bottom: 20px; border-radius: 10px"
      >
        <p class="item_title">品牌人群画像对比</p>
        <div class="profileWrap">
          <div>
            <sovBrandCompareProfile
                :xAxisData="xAxisData"
                :profileData="arr1"
                :title="'性别占比'"
                :color="['rgb(83, 186, 210)', '#e893a8']"
                :legend="genderLegend"
                :ref="'compareProfile' + Math.random()"
            ></sovBrandCompareProfile>
          </div>
          <div>
            <sovBrandCompareProfile
                :xAxisData="xAxisData"
                :legend="ageLegend"
                :title="'年龄占比'"
                :profileData="arr"
                :ref="'compareProfile' + Math.random()"
            ></sovBrandCompareProfile>
          </div>
        </div>
        <div class="profileWrap">
          <div>
            <sovBrandCompareProfile
                :xAxisData="xAxisData"
                :profileData="cityArr"
                :title="'城市占比'"
                :color="['#32a3be', 'rgb(83, 186, 210)', '#e893a8', '#e37893']"
                :legend="cityLegend"
                :ref="'compareProfile' + Math.random()"
            ></sovBrandCompareProfile>
          </div>
          <div>
            <sovBrandCompareProfile
                :xAxisData="xAxisData"
                :legend="incomeLegend"
                :title="'收入水平占比'"
                :profileData="incomeArr"
                :ref="'compareProfile' + Math.random()"
            ></sovBrandCompareProfile>
          </div>
        </div>
      </el-card>

      <div class="publicStyle">
        <div class="publicTitleStyle">
          产品PGC内容分析
          <div class="segClass">
            <span
                :style="{
              marginRight: '10px',
            }"
            >目标人群</span
            >
            <el-select
                v-model="segValue"
                size="mini"
                class="m-2"
                @change="changeTopSeg"
                placeholder="请选择人群"
            >
              <el-option
                  v-for="item in crowdListComputed"
                  :key="item.key"
                  :label="item.name"
                  :value="item.key"
              />
            </el-select>
          </div>
        </div>
        <div class="headBox">
          <div>
            <el-radio-group size="mini" v-model="brandRadio">
              <el-radio-button
                  v-for="item in productNameList"
                  :key="item.label"
                  :label="item.value"
              >{{ item.label }}
              </el-radio-button>
            </el-radio-group>
          </div>
          <div class="rightWrap">
            <span class="searchText">查找产品/品类关键词</span>
            <el-input
                @keyup.enter="handleSearch"
                v-model="searchInput"
                placeholder="请输入内容"
            ></el-input>
            <!-- <span @click="searchBtn" class="btnPublic btnSearch">搜索</span> -->
            <el-button
                size="mini"
                @click="searchBtn"
                class="btnPublic"
                style="width: 64px"
            >搜索
            </el-button>
            <!-- <el-button
              type="primary"
              :loading="btnLoading"
              size="mini"
              class="btnPublicBtn"
              @click="downContent"
              >下载<i class="el-icon-download downIconClass"></i
            ></el-button> -->
            <div>
              <svg-icon
                  icon-class="downloadIcon"
                  class-name="downloadIcon"
              ></svg-icon>
              <el-button
                  :loading="btnLoading"
                  class="noborderBtn"
                  type="text"
                  style="margin-right:0;color:#000;"
                  @click="downContent"
              >
                下载
              </el-button>
            </div>
          </div>
        </div>
        <div class="kolContentTable" style="margin-top: 10px">
          <el-table
              height="520"
              :data="kolContentTableDataCopy"
              v-loading="articleTopLoading"
              style="max-height: 450px; overflow: auto"
          >
            <el-table-column
                prop="brandName"
                align="center"
                width="100"
                label="品牌"
                fixed
            >
            </el-table-column>
            <el-table-column
                prop="productName"
                align="center"
                show-overflow-tooltip
                width="160"
                label="产品"
                fixed
            >
            </el-table-column>
            <el-table-column
                prop="kolName"
                align="center"
                show-overflow-tooltip
                width="100"
                label="KOL名称"
                fixed
            >
            </el-table-column>
            <el-table-column
                prop="platform"
                align="center"
                width="130"
                label="平台"
                fixed
            >
              <template #header>
                <el-select v-model="selPlatform" placeholder="请选择">
                  <el-option
                      v-for="item in platform"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                  >
                  </el-option>
                </el-select>
              </template>
              <template #default="{ row }">
                {{ platformToZn(row.platform) }}
              </template>
            </el-table-column>
            <el-table-column
                prop="tier"
                align="center"
                show-overflow-tooltip
                label="KOL级别"
            >
              <template #default="{ row }">
                <!--              {{-->
                <!--                row.tier === 0-->
                <!--                  ? "KOC"-->
                <!--                  : row.tier === 1-->
                <!--                  ? "Tier1"-->
                <!--                  : row.tier === 2-->
                <!--                  ? "Tier2"-->
                <!--                  : row.tier === 3-->
                <!--                  ? "Tier3"-->
                <!--                  : "&#45;&#45;"-->
                <!--              }}-->
                {{row.tierStr}}
              </template>
            </el-table-column>
            <el-table-column
                prop="kolFans"
                width="130"
                align="center"
                label="粉丝量"
            >
              <template #default="{ row }">
                {{ sliceDecimal(row.kolFans) }}
              </template>
            </el-table-column>
            <el-table-column
                prop="taRatioPct"
                align="center"
                show-overflow-tooltip
                label="TA浓度%"
                width="100"
            >
              <template #default="{ row }">
                {{ row.taRatioPct === null ? "--" : row.taRatioPct }}
              </template>
            </el-table-column>

            <el-table-column
                prop="link"
                align="center"
                show-overflow-tooltip
                width="200"
                label="文章链接"
            >
              <template #default="{ row }">
                <a
                    v-if="row.title"
                    target="_blank"
                    style="color: #51a6f0"
                    :href="row.link"
                >{{ row.title }}</a
                >
                <a
                    target="_blank"
                    style="color: #51a6f0"
                    :href="row.link"
                    v-else
                >{{ row.link === null ? "--" : row.link }}</a
                >
              </template>
            </el-table-column>
            <el-table-column
                width="150"
                prop="postDate"
                align="center"
                label="发文日期"
            >
            </el-table-column>
            <el-table-column
                prop="exposure"
                align="center"
                width="130"
                label="阅读量"
            >
              <template #default="{ row }">
                {{ sliceDecimal(row.exposure) }}
              </template>
            </el-table-column>
            <el-table-column
                width="120"
                prop="engagement"
                align="center"
                label="互动量"
            >
              <template #default="{ row }">
                {{ sliceDecimal(row.engagement) }}
              </template>
            </el-table-column>
            <el-table-column prop="waterPct" align="center" label="水量%">
              <template #default="{ row }">
                {{ row.waterPct === null ? "--" : row.waterPct }}
              </template>
            </el-table-column>
            <el-table-column
                width="120"
                prop="price"
                align="center"
                label="投放费用"
            >
              <template #default="{ row }">
                {{ row.price === null ? "--" : sliceDecimal(row.price) }}
              </template>
            </el-table-column>
            <el-table-column prop="cpm" align="center" label="CPM">
              <template #default="{ row }">
                {{ row.cpm === null ? "--" : "￥" + sliceDecimal(row.cpm) }}
              </template>
            </el-table-column>
            <el-table-column prop="cpe" align="center" label="CPE">
              <template #default="{ row }">
                {{ row.cpe === null ? "--" : "￥" + sliceDecimal(row.cpe) }}
              </template>
            </el-table-column>
            <el-table-column prop="taCpm" align="center" label="TA CPM">
              <template #default="{ row }">
                {{ row.taCpm === null ? "--" : "￥" + sliceDecimal(row.taCpm) }}
              </template>
            </el-table-column>
            <el-table-column prop="taCpe" align="center" label="TA CPE">
              <template #default="{ row }">
                {{ row.taCpe === null ? "--" : "￥" + row.taCpe }}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import sovTitle from "./sovProductComponents/sovTitle";
import sovGeneralView from "./sovProductComponents/sovGeneralView";
import sovProductCompareTa from "./sovProductComponents/sovProductCompareTa.vue";
import sovDistribute from "./sovProductComponents/sovDistribute";
import sovChartCart from "./sovProductComponents/sovChartCart";
import sovBrandCompareProfile from "../sovBrand/sovComponents/sovBrandCompareProfileData.vue";
import {
  getSovProductComparisonOverview,
  getProductTrend,
  getProductMonth,
  getProductArticleTop,
  downLoadProductArticleReport,
  getSovProductCommparisonPersona,
  getSovProductComparsionPlatformTa,
} from "@/api/sov/sovProduct";
import {
  setStartTime,
  setEndTime,
  setStartWeekTime,
  setEndWeekTime,
  setStartMonthTime,
  setEndMonthTime,
} from "../sovCommonFunc/index";
import storage from "@/utils/storage";
import { mapMutations, mapState } from "vuex";

export default {
  data() {
    return {
      segValue: "32_1",
      generalSegValue: "32_1",
      timeRange: "1mon",
      viewLoading: false,
      taLoading: true,
      chartProductId: "",
      generalViewTableData: [],
      productCompareTaTableData: [],
      echartsTime: "day",
      yType: "exposure",
      originLegend: [],
      originSeries: [],
      chartData: {
        echartxAxis: {
          data: [
            201905, 201906, 201907, 201908, 201909, 201910, 201911, 201912,
            202001, 202002,
          ],
          type: "category",
        },
        echartSeries: [
          {
            data: [
              73079874, 73584250, 55022549, 62489669, 54538919, 42380599,
              28357890, 42266074, 53824757, 38509705,
            ],
            name: "",
            type: "line",
            smooth: true,
          },
        ],
        echartColor: ["rgb(83, 186, 210)", "#e37893", "#daae3c"],
      },
      defaultColors: [
        "#2c7d98",
        "#d1eef8",
        "#f0a3b6",
        "#a02947",
        "#06c1ff",
        "#65aeb1",
        "#bda29a",
        "#ff7c7c",
        "#ffd3d3",
        "#db7cae",
        "#d3baed",
        "#966767",
        "#4fe3ff",
        "#ffa86d",
        "#6797fe",
        "#6c80fe",
        "#ffb57e",
        "#4ebcb4",
      ],
      originData: [],
      echartLegend: [],
      timeMonth: "",
      timeYear: "",
      brandRadio: "",
      platform: [
        {
          label: "全平台",
          value: "all",
        },
        {
          label: "微信",
          value: "wechat",
        },
        {
          label: "微博",
          value: "weibo",
        },
        {
          label: "抖音",
          value: "douyin",
        },
        {
          label: "小红书",
          value: "xiaohongshu",
        },
        {
          label: "哔哩哔哩",
          value: "bilibili",
        },
      ],
      selPlatform: "all",
      kolContentTableDataCopy: [],
      page: 1,
      articleTopLoading: false,
      btnLoading: false,
      searchInput: "",
      xAxisData: [],
      arr: [],
      arr1: [],
      cityArr: [],
      incomeArr: [],
      ageLegend: [
        "18-28岁",
        "23-29岁",
        "30-34岁",
        "35-39岁",
        "40-44岁",
        "45-54岁",
        ">=55岁",
      ],
      genderLegend: ["男", "女"],
      cityLegend: ["一线城市", "二线城市", "三线城市", "四线城市"],
      incomeLegend: ["贫困", "低收入", "小康", "中产", "大众富裕", "富裕"],
    };
  },
  props: {
    products: {
      type: String,
      default: "",
    },
    productNameList: {
      type: Array,
      default() {
        return [];
      },
    },
    duration: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapState(["productsSeg"]),
    crowdListComputed() {
      return this.formatCrowdList(storage.getItem("corwdListStorage"));
    },
  },
  mounted() {
    let doms = document.querySelector(
      ".kolContentTable .el-table__body-wrapper"
    );
    doms.addEventListener("scroll", this.func(this.scrollHandle, 1000));
    this.chartProductId = this.products;
    this.timeRange = JSON.parse(this.duration);
    this.init();
  },
  methods: {
    ...mapMutations({
      setProductsSeg: "SET_PRODUCTSSEG",
    }),
    formatCrowdList(list = []) {
      let tempList = [];
      list.forEach((v) => {
        if (v.key !== null) {
          tempList.push(v);
        }
      });
      return tempList;
    },
    changeSeg(val) {
      console.log(val);
      if (val) {
        this.generalSegValue = val;
        this.getSovProductComparsionPlatformTaFunc();
      }
    },
    changeTopSeg(val) {
      this.setProductsSeg(val);
    },
    async getSovProductComparsionPlatformTaFunc() {
      this.taLoading = true;
      let res = await getSovProductComparsionPlatformTa({
        startMonth: this.timeRange[0],
        endMonth: this.timeRange[1],
        id: this.products,
        seg: this.productsSeg,
      });
      if (res.code === 0) {
        res.data.forEach((v, i) => {
          v.id = i + 1;
          if (v.details && v.details.length !== 0) {
            v.details.forEach((k) => {
              k.id = Math.random() * 2 + 1;
            });
          }
        });
        console.log(res.data);
        this.productCompareTaTableData = res.data;
      } else {
        this.$message.error("获取数据失败，请联系管理员!");
      }
      this.taLoading = false;
    },
    async getSovProductCommparisonPersona() {
      let res = await getSovProductCommparisonPersona({
        // duration: this.timeRange,
        startMonth: this.timeRange[0],
        endMonth: this.timeRange[1],
        id: this.chartProductId,
        // aggMonth: this.timeRange[1],
      });
      if (res.code === 0) {
        // let temp = [];
        this.xAxisData = Object.keys(res.data);
        let objs = Object.values(res.data);
        objs.forEach((v, i, self) => {
          if (i + 1 < self.length && self.length === 2) {
            for (let k in self[i].age) {
              for (let j in self[i + 1].age) {
                if (k === j) {
                  this.arr.push([self[i].age[k].pct, self[i + 1].age[j].pct]);
                }
              }
            }
          } else if (self.length === 3 && i + 2 < self.length) {
            console.log(self);
            for (let k in self[i].age) {
              for (let j in self[i + 1].age) {
                for (let l in self[i + 2].age) {
                  if (k === j && j === l) {
                    this.arr.push([
                      self[i].age[k].pct,
                      self[i + 1].age[j].pct,
                      self[i + 2].age[l].pct,
                    ]);
                  }
                }
              }
            }
          } else if (self.length === 4 && i + 3 < self.length) {
            for (let k in self[i].age) {
              for (let j in self[i + 1].age) {
                for (let l in self[i + 2].age) {
                  if (k === j && j === l) {
                    this.arr.push([
                      self[i].age[k].pct,
                      self[i + 1].age[j].pct,
                      self[i + 2].age[l].pct,
                      self[i + 3].age[l].pct,
                    ]);
                  }
                }
              }
            }
          } else if (self.length === 5 && i + 4 < self.length) {
            for (let k in self[i].age) {
              for (let j in self[i + 1].age) {
                for (let l in self[i + 2].age) {
                  if (k === j && j === l) {
                    this.arr.push([
                      self[i].age[k].pct,
                      self[i + 1].age[j].pct,
                      self[i + 2].age[l].pct,
                      self[i + 3].age[l].pct,
                      self[i + 4].age[l].pct,
                    ]);
                  }
                }
              }
            }
          }
        });
        objs.forEach((v, i, self) => {
          if (i + 1 < self.length && self.length === 2) {
            for (let k in self[i].gender) {
              for (let j in self[i + 1].gender) {
                if (k === j) {
                  this.arr1.push([
                    self[i].gender[k].pct,
                    self[i + 1].gender[j].pct,
                  ]);
                }
              }
            }
          } else if (self.length === 3 && i + 2 < self.length) {
            console.log(self);
            for (let k in self[i].gender) {
              for (let j in self[i + 1].gender) {
                for (let l in self[i + 2].gender) {
                  if (k === j && j === l) {
                    this.arr1.push([
                      self[i].gender[k].pct,
                      self[i + 1].gender[j].pct,
                      self[i + 2].gender[l].pct,
                    ]);
                  }
                }
              }
            }
          } else if (self.length === 4 && i + 3 < self.length) {
            console.log(self);
            for (let k in self[i].gender) {
              for (let j in self[i + 1].gender) {
                for (let l in self[i + 2].gender) {
                  if (k === j && j === l) {
                    this.arr1.push([
                      self[i].gender[k].pct,
                      self[i + 1].gender[j].pct,
                      self[i + 2].gender[l].pct,
                      self[i + 3].gender[l].pct,
                    ]);
                  }
                }
              }
            }
          } else if (self.length === 5 && i + 4 < self.length) {
            console.log(self);
            for (let k in self[i].gender) {
              for (let j in self[i + 1].gender) {
                for (let l in self[i + 2].gender) {
                  if (k === j && j === l) {
                    this.arr1.push([
                      self[i].gender[k].pct,
                      self[i + 1].gender[j].pct,
                      self[i + 2].gender[l].pct,
                      self[i + 3].gender[l].pct,
                      self[i + 4].gender[l].pct,
                    ]);
                  }
                }
              }
            }
          }
        });
        objs.forEach((v, i, self) => {
          if (i + 1 < self.length && self.length === 2) {
            for (let k in self[i].city_tier) {
              for (let j in self[i + 1].city_tier) {
                if (k === j) {
                  this.cityArr.push([
                    self[i].city_tier[k].pct,
                    self[i + 1].city_tier[j].pct,
                  ]);
                }
              }
            }
          } else if (self.length === 3 && i + 2 < self.length) {
            console.log(self);
            for (let k in self[i].city_tier) {
              for (let j in self[i + 1].city_tier) {
                for (let l in self[i + 2].city_tier) {
                  if (k === j && j === l) {
                    this.cityArr.push([
                      self[i].city_tier[k].pct,
                      self[i + 1].city_tier[j].pct,
                      self[i + 2].city_tier[l].pct,
                    ]);
                  }
                }
              }
            }
          } else if (self.length === 4 && i + 3 < self.length) {
            console.log(self);
            for (let k in self[i].city_tier) {
              for (let j in self[i + 1].city_tier) {
                for (let l in self[i + 2].city_tier) {
                  if (k === j && j === l) {
                    this.cityArr.push([
                      self[i].city_tier[k].pct,
                      self[i + 1].city_tier[j].pct,
                      self[i + 2].city_tier[l].pct,
                      self[i + 3].city_tier[l].pct,
                    ]);
                  }
                }
              }
            }
          } else if (self.length === 5 && i + 4 < self.length) {
            console.log(self);
            for (let k in self[i].city_tier) {
              for (let j in self[i + 1].city_tier) {
                for (let l in self[i + 2].city_tier) {
                  if (k === j && j === l) {
                    this.cityArr.push([
                      self[i].city_tier[k].pct,
                      self[i + 1].city_tier[j].pct,
                      self[i + 2].city_tier[l].pct,
                      self[i + 3].city_tier[l].pct,
                      self[i + 4].city_tier[l].pct,
                    ]);
                  }
                }
              }
            }
          }
        });
        objs.forEach((v, i, self) => {
          if (i + 1 < self.length && self.length === 2) {
            for (let k in self[i].income) {
              for (let j in self[i + 1].income) {
                if (k === j) {
                  this.incomeArr.push([
                    self[i].income[k].pct,
                    self[i + 1].income[j].pct,
                  ]);
                }
              }
            }
          } else if (self.length === 3 && i + 2 < self.length) {
            console.log(self);
            for (let k in self[i].income) {
              for (let j in self[i + 1].income) {
                for (let l in self[i + 2].income) {
                  if (k === j && j === l) {
                    this.incomeArr.push([
                      self[i].income[k].pct,
                      self[i + 1].income[j].pct,
                      self[i + 2].income[l].pct,
                    ]);
                  }
                }
              }
            }
          } else if (self.length === 4 && i + 3 < self.length) {
            console.log(self);
            for (let k in self[i].income) {
              for (let j in self[i + 1].income) {
                for (let l in self[i + 2].income) {
                  if (k === j && j === l) {
                    this.incomeArr.push([
                      self[i].income[k].pct,
                      self[i + 1].income[j].pct,
                      self[i + 2].income[l].pct,
                      self[i + 3].income[l].pct,
                    ]);
                  }
                }
              }
            }
          } else if (self.length === 5 && i + 4 < self.length) {
            console.log(self);
            for (let k in self[i].income) {
              for (let j in self[i + 1].income) {
                for (let l in self[i + 2].income) {
                  if (k === j && j === l) {
                    this.incomeArr.push([
                      self[i].income[k].pct,
                      self[i + 1].income[j].pct,
                      self[i + 2].income[l].pct,
                      self[i + 3].income[l].pct,
                      self[i + 4].income[l].pct,
                    ]);
                  }
                }
              }
            }
          }
        });
        this.arr = [...this.arr];
        this.arr1 = [...this.arr1];
        this.cityArr = [...this.cityArr];
        this.incomeArr = [...this.incomeArr];
        console.log(this.arr1);
      } else {
        this.$message.error("请求数据失败，请联系管理员!");
      }
    },
    buildBrandList() {
      this.campaignList = this.productNameList;
      let ids = [];
      this.campaignList.forEach((v) => {
        ids.push(v.value);
      });
      this.campaignList.unshift({
        label: "全部",
        value: ids.join(","),
      });
      this.brandRadio = this.productNameList[0].value;
    },
    func(func, delay) {
      var timer = null;
      return () => {
        var args = arguments;
        if (!timer) {
          timer = setTimeout(function () {
            func.apply(this, args);
            timer = null;
          }, delay);
        }
      };
    },
    handleSearch() {
      this.searchBtn();
    },
    searchBtn() {
      this.kolContentTableDataCopy = [];
      this.getProductArticleTop();
    },
    downContent() {
      const params = {
        // duration: this.timeRange,
        startMonth: this.timeRange[0],
        endMonth: this.timeRange[1],
        id: this.chartProductId,
        platform: this.selPlatform,
        searchWord: this.searchInput,
        hotword: "",
        seg: this.productsSeg,
        // aggMonth: this.timeRange[1],
      };
      this.btnLoading = true;
      this.$message({
        type: "info",
        message: "文件正在解析，请耐心等待",
      });
      downLoadProductArticleReport(params)
        .then(() => {
          this.$message({
            type: "success",
            message: "文件正在下载",
          });
          this.btnLoading = false;
        })
        .catch(() => {
          this.$message.error("文件下载失败,请联系管理员!");
        });
    },
    scrollHandle() {
      let timeOut = null;
      clearTimeout(timeOut);
      timeOut = setTimeout(() => {
        if (
          document.querySelector(".kolContentTable .el-table__body-wrapper")
            .scrollTop +
            670 >
          document.querySelector(".kolContentTable .el-table__body-wrapper")
            .scrollHeight
        ) {
          if (this.page * this.size > this.total) {
            return;
          }
          this.page++;
          this.getProductArticleTop();
        }
      }, 400);
    },
    async getProductArticleTop() {
      this.articleTopLoading = true;
      let res = await getProductArticleTop({
        // duration: this.timeRange,
        startMonth: this.timeRange[0],
        endMonth: this.timeRange[1],
        id: this.brandRadio,
        searchWord: this.searchInput,
        platform: this.selPlatform,
        sortColumn: "exposure",
        sortDirection: "desc",
        hotword: "",
        page: this.page,
        size: 20,
        seg: this.productsSeg,
        // aggMonth: this.timeRange[1],
      });
      if (res.code === 0) {
        this.kolContentTableData = res.data.adsTopArticles;
        this.kolContentTableDataCopy.push(...this.kolContentTableData);
        console.log(this.kolContentTableDataCopy);
        this.articleTopLoading = false;
        this.total = res.data.total;
      } else {
        this.$message.error("获取数据失败，请联系管理员!");
      }
    },
    init() {
      this.buildBrandList();
      this.getProductMonth();
      this.getSovProductComparisonOverview();
      this.getProductTrend();
      this.getProductArticleTop();
      this.getSovProductCommparisonPersona();
      this.getSovProductComparsionPlatformTaFunc();
    },
    async getProductMonth() {
      let res = await getProductMonth();
      console.log(res);
      if (res.code === 0) {
        this.timeMonth = res.data.split("-")[1];
        this.timeYear = res.data.split("-")[0];
      } else {
        this.$message.error("获取时间失败，请联系管理员!");
      }
    },
    async getProductTrend() {
      let res = await getProductTrend({
        // duration: this.timeRange,
        startMonth: this.timeRange[0],
        endMonth: this.timeRange[1],
        id: this.chartProductId,
        xType: this.echartsTime,
        yType: this.yType,
        // aggMonth: this.timeRange[1],
      });
      if (res.code === 0) {
        this.originData = [].concat(res.data);
        this.initTrendData(res.data);
        this.originLegend = this.echartLegend;
        this.originSeries = this.chartData.echartSeries;
      } else {
        this.$message.error("获取数据失败，请联系管理员!");
      }
    },
    initTrendData(data) {
      if (this.trendType === "cpi") {
        this.tempType = "trend";
      } else {
        this.tempType = "exposureTrend";
      }
      this.echartLegend = [];
      const startTimeArr = [];
      const endTimeArr = [];
      data.forEach((campaign, i) => {
        console.log(campaign);
        this.echartLegend.push({
          name: campaign.name,
          color: this.chartData.echartColor.concat(this.defaultColors)[i],
          index: Math.random() * 2 * Math.random(),
        });
        console.log(this.echartLegend);
        console.log(this.defaultColors);
        if (campaign.trend.length !== 0) {
          startTimeArr.push(campaign.trend[0].xAxis);
          const lastIndex = campaign.trend.length - 1;
          endTimeArr.push(campaign.trend[lastIndex].xAxis);
        }
      });
      if (this.echartsTime === "day") {
        // day
        startTimeArr.push(
          setStartTime(this.timeRange[0], this.timeMonth, this.timeYear)
        );
        endTimeArr.push(
          setEndTime(this.timeRange[1], this.timeMonth, this.timeYear)
        );
        this.formatDayDate(startTimeArr, endTimeArr);
      } else if (this.echartsTime === "week") {
        // week
        startTimeArr.push(
          setStartWeekTime(this.timeRange[0], this.timeMonth, this.timeYear)
        );
        endTimeArr.push(
          setEndWeekTime(this.timeRange[1], this.timeMonth, this.timeYear)
        );
        this.formatWeekDate(startTimeArr, endTimeArr);
      } else {
        // month
        startTimeArr.push(
          setStartMonthTime(this.timeRange[0], this.timeMonth, this.timeYear)
        );
        endTimeArr.push(
          setEndMonthTime(this.timeRange[1], this.timeMonth, this.timeYear)
        );
        this.formatMonthDate(startTimeArr, endTimeArr);
      }
    },
    formatDayDate(startTimeArr, endTimeArr, type) {
      const startTimeStamp = startTimeArr
        .map((time) => new Date(time).getTime())
        .sort((a, b) => a - b)[0];
      const startTime = this.timeStampToDate(startTimeStamp);
      const endTimeStamp = endTimeArr
        .map((time) => new Date(time).getTime())
        .sort((a, b) => b - a)[0];
      const endTime = this.timeStampToDate(endTimeStamp);
      this.getDayAllDate(startTime, endTime, type);
    },
    getDayAllDate(start, end, type) {
      let dateArr = [];
      let startArr = start.split("-");
      let endArr = end.split("-");
      let db = new Date();
      db.setFullYear(startArr[0], startArr[1] - 1, startArr[2]);
      let de = new Date();
      de.setFullYear(endArr[0], endArr[1] - 1, endArr[2]);
      let stampDb = db.getTime();
      let stampDe = de.getTime();
      let stamp = stampDb;
      const oneDay = 24 * 60 * 60 * 1000;
      for (stamp; stamp <= stampDe; stamp += oneDay) {
        dateArr.push(this.timeStampToDate(stamp));
      }
      this.chartData.echartxAxis.data = dateArr;
      if (type === "product") {
        this.chartDataProduct.echartxAxis.data = dateArr;
      }
      if (type === "cate") {
        this.getCateDataFromOrigin();
      } else if (type === "product") {
        this.getProductDataFromOrigin();
      } else {
        this.getDataFromOrigin();
      }
    },
    formatWeekDate(startTimeArr, endTimeArr, type) {
      const nStartTimeArr = startTimeArr.map((time) => time.slice(0, 10));
      const nEndTimeArr = endTimeArr.map((time) => time.slice(-10));
      const startTimeStamp = nStartTimeArr
        .map((time) => new Date(time).getTime())
        .sort((a, b) => a - b)[0];
      const startTime = this.timeStampToDate(startTimeStamp);
      const endTimeStamp = nEndTimeArr
        .map((time) => new Date(time).getTime())
        .sort((a, b) => b - a)[0];
      const endTime = this.timeStampToDate(endTimeStamp);
      this.getWeekAllDate(startTime, endTime, type);
    },
    getWeekAllDate(start, end, type) {
      let startArr = start.split("-");
      let endArr = end.split("-");
      let db = new Date();
      db.setFullYear(startArr[0], startArr[1] - 1, startArr[2]);
      let de = new Date();
      de.setFullYear(endArr[0], endArr[1] - 1, endArr[2]);
      let stampDb = db.getTime();
      let stampDe = de.getTime();
      let stamp = stampDb;
      const oneWeek = 7 * 24 * 60 * 60 * 1000;
      let stampArr = [];
      for (stamp; stamp <= stampDe; stamp += oneWeek) {
        stampArr.push(stamp);
      }
      const sixDay = 6 * 24 * 60 * 60 * 1000;
      let dateArr = [];
      dateArr = stampArr.map((stamp) => {
        const weekLastDayStamp = stamp + sixDay;
        return (
          this.timeStampToDate(stamp) +
          " ~ " +
          this.timeStampToDate(weekLastDayStamp)
        );
      });
      this.chartData.echartxAxis.data = dateArr;
      if (type === "product") {
        this.chartDataProduct.echartxAxis.data = dateArr;
      }
      if (type === "cate") {
        this.getCateDataFromOrigin();
      } else if (type === "product") {
        this.getProductDataFromOrigin();
      } else {
        this.getDataFromOrigin();
      }
    },
    formatMonthDate(startTimeArr, endTimeArr, type) {
      const startTimeStamp = startTimeArr
        .map((time) => new Date(time).getTime())
        .sort((a, b) => a - b)[0];
      const startTime = this.timeStampToDate(startTimeStamp);
      const endTimeStamp = endTimeArr
        .map((time) => new Date(time).getTime())
        .sort((a, b) => b - a)[0];
      const endTime = this.timeStampToDate(endTimeStamp);
      this.getMonthAllDate(startTime, endTime, type);
    },
    getMonthAllDate(start, end, type) {
      let startArr = start.split("-");
      let startYear = startArr[0] * 1;
      let startMonth = startArr[1] * 1;
      let endArr = end.split("-");
      let endYear = endArr[0] * 1;
      let endMonth = endArr[1] * 1;
      let dateArr = [];
      if (startYear < endYear) {
        for (startMonth; startMonth <= 12; startMonth++) {
          const tempStr =
            startYear + "-" + (startMonth < 10 ? "0" + startMonth : startMonth);
          dateArr.push(tempStr);
          if (startMonth === 12 && startYear + 1 < endYear) {
            startYear++;
            startMonth = 0;
          }
        }
        for (let i = 1; i <= endMonth; i++) {
          const tempStr = endYear + "-" + (i < 10 ? "0" + i : i);
          dateArr.push(tempStr);
        }
      } else {
        for (startMonth; startMonth <= endMonth; startMonth++) {
          const tempStr =
            endYear + "-" + (startMonth < 10 ? "0" + startMonth : startMonth);
          dateArr.push(tempStr);
        }
      }
      this.chartData.echartxAxis.data = dateArr;
      if (type === "product") {
        this.chartDataProduct.echartxAxis.data = dateArr;
      }
      if (type === "cate") {
        this.getCateDataFromOrigin();
      } else if (type === "product") {
        this.getProductDataFromOrigin();
      } else {
        this.getDataFromOrigin();
      }
    },
    timeStampToDate(timestamp) {
      const date = new Date(timestamp);
      const Y = date.getFullYear();
      const M =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      const D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      return Y + "-" + M + "-" + D;
    },
    getDataFromOrigin() {
      this.chartData.echartSeries = this.originData.map((campaign) => {
        const seriesItem = {
          data: [],
          name: "",
          type: "line",
          smooth: true,
        };

        seriesItem.name = campaign.name;
        let campaignxAxis = [];
        if (campaign.trend.length !== 0) {
          // 重写逻辑：从整个时间轴上截取时间段，段内补0，段外补null
          const startTimeIndex = this.chartData.echartxAxis.data.indexOf(
            campaign.trend[0].xAxis
          );
          const endTimeIndex = this.chartData.echartxAxis.data.indexOf(
            campaign.trend[campaign.trend.length - 1].xAxis
          );
          campaignxAxis = this.chartData.echartxAxis.data.slice(
            startTimeIndex,
            endTimeIndex + 1
          );
        }

        seriesItem.data = this.chartData.echartxAxis.data.map((xAxis) => {
          let data = 0;
          if (campaignxAxis.includes(xAxis)) {
            const trend = campaign.trend.find((item) => item.xAxis === xAxis);
            trend ? (data = trend.yAxis) : (data = 0);
          }
          return data;
        });
        return seriesItem;
      });
    },
    timeSel(val) {
      this.echartsTime = val;
      this.getProductTrend();
    },
    seeTotalChart(val, b) {
      this.yType = b;
      this.trendType = b;
      console.log(val);
      if (val) {
        this.chartProductId = val.join(",");
      }
      this.getProductTrend();
    },
    seeBrandChart(val, b) {
      this.yType = b;
      this.trendType = b;
      if (val) {
        this.chartProductId = val.join(",");
      }
      this.getProductTrend();
    },
    async getSovProductComparisonOverview() {
      this.viewLoading = true;
      let res = await getSovProductComparisonOverview({
        // duration: this.timeRange,
        startMonth: this.timeRange[0],
        endMonth: this.timeRange[1],
        id: this.products,
        // aggMonth: this.timeRange[1],
      });
      if (res.code === 0) {
        this.formatNull(res.data);
        this.generalViewTableData = res.data;
        this.viewLoading = false;
      } else {
        this.$message.error("获取数据失败，请联系管理员!");
      }
    },
    forBack() {
      this.$router.push({ name: "sovProductList" });
    },
  },
  watch: {
    productsSeg(val) {
      this.kolContentTableDataCopy = [];
      this.segValue = val;
      this.getSovProductComparsionPlatformTaFunc();
      this.getProductArticleTop();
    },
    brandRadio(val, oldVal) {
      console.log(val);
      console.log(oldVal);
      if (oldVal !== "") {
        this.kolContentTableDataCopy = [];
        this.page = 1;
        this.getProductArticleTop();
      }
    },
    selPlatform() {
      this.page = 1;
      this.kolContentTableDataCopy = [];
      this.getProductArticleTop();
    },
  },
  components: {
    sovTitle,
    sovGeneralView,
    sovProductCompareTa,
    sovDistribute,
    sovChartCart,
    sovBrandCompareProfile,
  },
};
</script>

<style lang="scss" scoped>
.sovProductCompareWrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap:20px;
  // height: 100%;
  .profileWrap {
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: space-around;
    div {
      width: 47%;
      height: 100%;
    }
  }
  .segClass {
    float: right;
    margin-right: 20px;
    font-size: 14px;
  }
}
</style>

<style lang="scss">
.sovProductCompareWrap {
  .selectClass {
    .el-scrollbar__view.el-select-dropdown__list {
      margin-top: 5px;
    }

    .el-select__caret.el-input__icon.el-icon-arrow-up {
      line-height: 28px;
    }

    font-size: 14px;

    .el-input__inner {
      width: 285px;
      height: 28px;
    }

    .el-input--suffix {
      height: 35px;
      line-height: 35px;
    }
  }

  .firstRowStyle {
    td div {
      font-size: 18px;
      color: #393a4d;
      font-weight: 400;
    }
  }

  .otherStyle {
    td div {
      font-size: 14px;
      color: #34355b;
      font-weight: 400;
    }
  }

  .rowStyle {
    td div {
      font-size: 14px;
      color: #34355b;
      font-weight: 400;
    }
  }

  //.el-radio__input.is-checked .el-radio__inner {
  //  background-color: #fff;
  //  border-color: #dda634;
  //}
  //
  //.el-radio__input.is-checked + .el-radio__label {
  //  color: #dda634;
  //}
  //
  //.el-radio__inner:hover {
  //  border-color: #dda634;
  //}
  //
  //.el-radio__inner {
  //  width: 20px;
  //  height: 20px;
  //  background-color: #fff;
  //}
  //
  //.el-radio__inner::after {
  //  width: 10px;
  //  height: 10px;
  //  background: linear-gradient(180deg, #daae3c 0%, #e09524 100%);
  //}

  .el-radio {
    margin-right: 20px;
  }

  .distributeTable
    .el-table
    .el-table__header-wrapper
    .el-table__header
    thead
    > tr
    > th
    > .cell {
    color: #8c98a5;
  }

  .el-table__expanded-cell[class*="cell"] {
    background-color: #f9fcff;
    padding: 0;
    padding-left: 90px;
  }

  //.is-active {
  //  .el-radio-button__inner {
  //    background-color: #e09a29 !important;
  //    border-color: #e09a29 !important;
  //    color: #fff !important;
  //  }
  //}
  //
  //.el-radio-button__orig-radio:checked + .el-radio-button__inner {
  //  box-shadow: -1px 0 0 0 #e09a29;
  //}
  //
  //.el-radio-button {
  //  .el-radio-button__inner {
  //    background-color: #f8f9fe;
  //    color: #979797;
  //  }
  //}

  .rightWrap {
    display: flex;
    justify-content: flex-start;
    align-items:center;
    gap:10px;
    .el-input {
      .el-input__inner {
        height: 100%;
      }
    }
  }

  .kolContentTable {
    .el-input--suffix .el-input__inner {
      height: 30px;
    }

    .el-input__suffix {
      top: 5px;
    }

    .el-select .el-input__suffix {
      top: -4px;
    }

    .el-input--suffix .el-input__icon {
      line-height: 30px;
    }
  }

  .distribute {
    .el-table [class*="el-table__row--level"] .el-table__expand-icon {
      margin-bottom: 3px;
    }
  }

  .distribute thead .is-left .cell {
    margin-left: 20px;
  }

  .el-table__placeholder {
    z-index: 1000;
    cursor: pointer;
  }
}
</style>

<style lang="scss" scoped>
.filterBoxFixed {
  width:20%;
  position: sticky;
  top:0;
  height: 100%;
}
.sovProductCompareWrap {
  width: 100%;
  // height: 100%;

  .publicStyle {
    margin-top: 20px;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

    .publicTitleStyle {
      padding-top: 20px;
      padding-left: 20px;
      font-size: 20px;
      color: #4f4f4f;
    }

    .downBtn {
      margin-right: 20px;
      background: linear-gradient(180deg, #4d7ad7 0%, #5e8cec 100%);
      border-radius: 4px;
      color: #fff;
      font-size: 14px;
      padding: 0 10px 1px;

      .downIconClass {
        font-size: 16px;
        margin-left: 10px;
      }
    }
  }

  .btnPublic {
    display: inline-block;
    height: 30px;
    text-align: center;
    background: linear-gradient(180deg, #4d7ad7 0%, #5e8cec 100%);
    border-radius: 4px;
    color: #fff;
  }
  .btnPublicBtn {
    // width: 72px !important;
    vertical-align: top;
    height: 30px;
    background: linear-gradient(180deg, #4d7ad7 0%, #5e8cec 100%);
    border-radius: 4px;
    color: #fff;
    margin-left: 30px;
  }

  .distribute {
    margin-bottom: 20px;
  }

  .container {
    font-size: 16px;
    position: relative;
    padding:0;
    //top: -10px;
    //background-color: #fff;
    //height: 62px;
    //line-height: 60px;
    //border: 1px solid #ebebeb;
    //border-radius: 10px;
    //padding: 0 20px;
    //color: #11263c;
    //box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

    .sovTitle {
      display: flex;
      align-items: center;

      .filter {
        flex: 1;
        display: flex;
        align-items: center;

        .brandText {
          line-height: 59px;
          color: #11263c;

          span {
            padding: 0 10px;
            border-right: 1px solid #d4d0db;
          }

          & :first-child {
            padding-left: 0;
          }

          & :last-child {
            padding-right: 0;
            border-right: none;
          }
        }
      }

      .returnBtn {
        color: #fff;
        width: 74px;
        padding: 0;
        background: linear-gradient(180deg, #4d7ad7 0%, #5e8cec 100%);
        border-radius: 4px;
      }
    }
  }

  .distributeTable {
    margin-top: 10px;

    .paddingClass {
      display: flex;
      height: 40px;
      line-height: 40px;

      .spanBox {
        flex: 1;
        display: flex;
        justify-content: space-around;
      }

      span {
        display: inline-block;
      }
    }
  }

  .btnGroupWrap {
    text-align: right;
    margin-right: 20px;

    .spandAll {
      width: 92px;
      height: 28px;
      line-height: 28px;
      text-align: left;
      padding-left: 8px;
      display: inline-block;
      background-color: #f4f3f3;
      color: #8c98a5;
      border-radius: 4px;
      cursor: pointer;

      .expandAllIclass {
        position: relative;
        left: 4px;
        top: -1px;
        transition: transform 0.2s ease-in-out;
      }

      .rotate {
        transform: rotate(-90deg);
      }
    }

    //.isExpandClass {
    //  background: linear-gradient(180deg, #daae3c 0%, #e09524 100%);
    //  color: #fff;
    //}
  }

  .item_title {
    font-size: 20px;
    margin-bottom: 20px;
    color: #4f4f4f;
  }

  .item_square_content {
    width: 280px;
    height: 190px;
    border: 1px solid #ebebeb;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 24px 22px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .space_between_item {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .icon_svg {
      font-size: 46px;
    }

    .line_svg {
      width: 100%;
      margin-bottom: 4px;
    }

    .big_text {
      font-size: 24px;
    }

    .normal_text {
      font-size: 20px;
    }

    .gray_text {
      color: #aeaaaa;
    }
  }

  .showCategorty {
    display: inline-block;
    width: 126px;
    height: 28px;
    line-height: 28px;
    background: linear-gradient(180deg, #4d7ad7 0%, #5e8cec 100%);
    border-radius: 4px;
    padding-left: 10px;
    color: #fff;
    cursor: pointer;

    .noteClass {
      position: relative;
      left: 8px;
      color: #fff;
      font-size: 16px;
      top: -1px;
    }
  }

  .headBox {
    height: 40px;
    padding: 0 20px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .searchText {
      font-size: 16px;
      color: #11263c;
    }

    .btnSearch {
      cursor: pointer !important;
      width: 64px;
    }

    .btnDown {
      cursor: pointer;
      width: 64px;
      text-align: left;
      padding-left: 10px;
      margin-left: 30px;

      .downIconClass {
        font-size: 16px;
        position: relative;
        top: -1px;
        left: 15px;
      }
    }

    .btnPublicBtn {
      .downIconClass {
        font-size: 16px;
        position: relative;
        top: 1px;
        left: 10px;
      }
    }

    .el-input {
      width: 123px;
      height: 28px;
      border-radius: 5px;
      margin-left: 20px;
      margin-right: 10px;
      line-height: 28px;

      .el-input__inner {
        height: 100%;
      }
    }
  }
}

.iconStyle {
  position: absolute;
  left: 78px;
  top: 50%;
  transform: translateY(-40%);
  font-size: 16px;
}

.imgClass {
  width: 100%;
  height: 100%;
  background-size: contain;
}
</style>
